import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import Dashboard from "./Dashboard";
import CareerStats from './CareerStats';
import { ContextStoreProvider } from "./context/ContextStore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PitchwiseStatistics from './PitchwiseStatistics';
import PointsTable from './PointsTable';


ReactDOM.createRoot(document.querySelector("#root")).render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
    <ContextStoreProvider>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard match={"od"} />} />
          <Route path="/pitchwise-statistics" element={<PitchwiseStatistics/>} />
          <Route path="/points-table" element={<PointsTable/>} />
          <Route path="/career" element={<CareerStats />} />
        </Routes>
      </BrowserRouter>
      {/* <Dashboard /> */}
      </ContextStoreProvider>
    </StyledEngineProvider>
  // </React.StrictMode>
);