// src/components/CareerStats.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Alert,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HomeIcon from '@mui/icons-material/Home';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { getCareerStats } from './apicalls';

// Tab Panel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`career-stats-tabpanel-${index}`}
      aria-labelledby={`career-stats-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Accessibility Props for Tabs
function a11yProps(index) {
  return {
    id: `career-stats-tab-${index}`,
    'aria-controls': `career-stats-tabpanel-${index}`,
  };
}

const CareerStats = () => {
  const [tabValue, setTabValue] = useState(0);
  const [careerBowlers, setCareerBowlers] = useState([]);
  const [careerBatters, setCareerBatters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Fetch Career Stats from API
  const fetchCareerStats = async () => {
    setRefreshing(true);
    try {
      let response = await getCareerStats(); // Updated API call
      const data = response.data;

      // Check if data exists
      if (data.career_bowlers) {
        // Add unique ID for DataGrid
        const bowlersWithId = data.career_bowlers.map((bowler, index) => ({
          id: index + 1,
          ...bowler,
        }));
        setCareerBowlers(bowlersWithId);
      }

      if (data.career_batters) {
        const battersWithId = data.career_batters.map((batter, index) => ({
          id: index + 1,
          ...batter,
        }));
        setCareerBatters(battersWithId);
      }

      setError('');
    } catch (err) {
      console.error('Error fetching career stats:', err);
      setError('Failed to fetch career statistics. Please try again later.');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchCareerStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Define Columns for Bowlers
  const bowlerColumns = [
    { field: 'player_name', headerName: 'Player Name', flex: 1, minWidth: 150 },
    { field: 'team_name', headerName: 'Team', flex: 1, minWidth: 120 },
    { field: 'overs', headerName: 'Overs', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'runs', headerName: 'Runs', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'wickets', headerName: 'Wickets', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'economy', headerName: 'Economy', type: 'number', flex: 0.7, minWidth: 100 },
    {
      field: 'bowling_average',
      headerName: 'Bowling Average',
      type: 'number',
      flex: 0.9,
      minWidth: 130,
    },
    {
      field: 'bowling_strike_rate',
      headerName: 'Bowling Strike Rate',
      type: 'number',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'bowling_sa_score',
      headerName: 'Bowling SA Score',
      type: 'number',
      flex: 1,
      minWidth: 140,
    },
    { field: 'no_matches', headerName: 'No. of Matches', type: 'number', flex: 1, minWidth: 130 },
  ];

  // Define Columns for Batters
  const batterColumns = [
    { field: 'player_name', headerName: 'Player Name', flex: 1, minWidth: 150 },
    { field: 'team_name', headerName: 'Team', flex: 1, minWidth: 120 },
    { field: 'runs', headerName: 'Runs', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'fours', headerName: 'Fours', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'sixes', headerName: 'Sixes', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'balls', headerName: 'Balls', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'strike_rate', headerName: 'Strike Rate', type: 'number', flex: 0.9, minWidth: 120 },
    { field: 'not_out', headerName: 'Not Out', type: 'number', flex: 0.7, minWidth: 100 },
    {
      field: 'batting_position',
      headerName: 'Batting Position',
      type: 'number',
      flex: 0.9,
      minWidth: 130,
    },
    { field: 'outs', headerName: 'Outs', type: 'number', flex: 0.7, minWidth: 80 },
    { field: 'no_matches', headerName: 'No. of Matches', type: 'number', flex: 1, minWidth: 130 },
    { field: 'average', headerName: 'Average', type: 'number', flex: 0.9, minWidth: 100 },
    { field: 'sa_score', headerName: 'SA Score', type: 'number', flex: 0.9, minWidth: 100 },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      {/* AppBar Section */}
      <AppBar position="fixed">
        <Toolbar
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" component="div">
            Battrick U600 Stats
          </Typography>
          <Button
            color="inherit"
            component={RouterLink}
            to="/"
            startIcon={<HomeIcon />}
            sx={{ textTransform: 'none' }}
          >
            {isMobile ? null : 'Home'}
          </Button>
        </Toolbar>
      </AppBar>
      {/* Spacer Toolbar to prevent content being hidden behind AppBar */}
      <Toolbar />
      {/* End of AppBar Section */}

      {/* Main Content */}
      <Box sx={{ width: '100%', padding: { xs: 1, sm: 2, md: 3 } }}>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          gutterBottom
          sx={{ textAlign: 'center', mb: { xs: 2, sm: 3 } }}
        >
          Career Statistics
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="career stats tabs"
              textColor="primary"
              indicatorColor="primary"
              variant={isMobile ? 'fullWidth' : 'standard'}
              sx={{ mb: { xs: 2, sm: 3 } }}
            >
              <Tab label="Bowlers" {...a11yProps(0)} />
              <Tab label="Batters" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              {careerBowlers.length > 0 ? (
                <Box sx={{ height: isMobile ? 400 : 600, width: '100%' }}>
                  <DataGrid
                    rows={careerBowlers}
                    columns={bowlerColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    autoHeight={!isMobile}
                    disableSelectionOnClick
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sx={{
                      '& .MuiDataGrid-cell': {
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.5 !important',
                        padding: '8px',
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    density={isMobile ? 'compact' : 'standard'}
                  />
                </Box>
              ) : (
                <Typography>No bowler data available.</Typography>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {careerBatters.length > 0 ? (
                <Box sx={{ height: isMobile ? 400 : 600, width: '100%' }}>
                  <DataGrid
                    rows={careerBatters}
                    columns={batterColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    autoHeight={!isMobile}
                    disableSelectionOnClick
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sx={{
                      '& .MuiDataGrid-cell': {
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.5 !important',
                        padding: '8px',
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    density={isMobile ? 'compact' : 'standard'}
                  />
                </Box>
              ) : (
                <Typography>No batter data available.</Typography>
              )}
            </TabPanel>
          </>
        )}

        {/* Refresh Button */}
        {!loading && !error && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={fetchCareerStats}
              disabled={refreshing}
              size={isMobile ? 'small' : 'medium'}
            >
              {refreshing ? 'Refreshing...' : 'Refresh'}
            </Button>
          </Box>
        )}
      </Box>
      {/* End of Main Content */}
    </Box>
  );
};

export default CareerStats;
