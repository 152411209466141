// src/components/ResponsiveDrawer.js

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Icon for "Go to Career"
import { Link as RouterLink } from "react-router-dom";
import DataTable from "./DataTable";
import PitchwiseStatistics from "./PitchwiseStatistics";
import PointsTable from "./PointsTable";
import { PieChart } from "react-minimal-pie-chart";
import ContextStore from "./context/ContextStore";
import { tournaments } from "./tournaments";

const drawerWidth = 240;

// Tab Panel Component (if needed elsewhere)
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`responsive-drawer-tabpanel-${index}`}
      aria-labelledby={`responsive-drawer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Accessibility Props for Tabs (if using Tabs)
function a11yProps(index) {
  return {
    id: `responsive-drawer-tab-${index}`,
    "aria-controls": `responsive-drawer-tabpanel-${index}`,
  };
}

function ResponsiveDrawer(props) {
  const storeContext = useContext(ContextStore);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [match, setMatch] = useState(tournaments[tournaments.length - 1].id);

  const selectedTournament = tournaments.find(
    (tournament) => tournament.id === match
  );

  useEffect(() => {
    storeContext.storeWinData(match);
  }, [match]);

  const handleSearchChange = (event) => {
    storeContext.setSearchInput(event.target.value);
  };

  const handleListClick = (buttonClicked) => {
    if (buttonClicked === "points_table") {
      storeContext.setSelectedListButton([false, false, true]);
    } else if (buttonClicked === "pitch_stats") {
      storeContext.setSelectedListButton([false, true, false]);
    } else if (buttonClicked === "career") {
      storeContext.setSelectedListButton([false, false, false]); // Adjust based on your logic
    } else {
      storeContext.setSelectedListButton([true, false, false]);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {/* Existing List Items */}
        <ListItem disablePadding>
          <ListItemButton
            selected={storeContext.selectedListButton[0]}
            onClick={() => handleListClick("stats")}
            // component={RouterLink}
            // to="/statistics"
          >
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText primary="Check Statistics" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={storeContext.selectedListButton[1]}
            onClick={() => handleListClick("pitch_stats")}
            // component={<PitchwiseStatistics match={match}/>}
            // to="/pitchwise-statistics"
          >
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Check Pitchwise Statistics" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={storeContext.selectedListButton[2]}
            onClick={() => handleListClick("points_table")}
            // component={<PointsTable match={match}/>}
            // to="/points-table"
          >
            <ListItemIcon>
              <SportsScoreIcon />
            </ListItemIcon>
            <ListItemText primary="Points Table" />
          </ListItemButton>
        </ListItem>

        <Divider sx={{ my: 1 }} />

        {/* New "Go to Career" List Item */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleListClick("career")}
            component={RouterLink}
            to="/career"
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Go to Career" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const defaultLabelStyle = {
    fontSize: "5px",
    fontFamily: "sans-serif",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* AppBar Section */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "nowrap", // Prevent wrapping
            minHeight: { xs: "56px", sm: "64px" }, // Ensure consistent height
          }}
        >
          {/* Left Side: Hamburger Icon and Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexShrink: 0, // Prevents shrinking on small screens
            }}
          >
            {/* Hamburger Icon for Mobile */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }} // Hide on desktop
            >
              <MenuIcon />
            </IconButton>

            {/* Title */}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: isMobile ? "center" : "left",
                ml: isMobile ? 0 : 0,
                fontSize: { xs: "1rem", sm: "1.25rem" }, // Adjust font size for readability
              }}
            >
              Battrick U600 Stats
            </Typography>
          </Box>

          {/* Right Side: Tournament Selector and "Go to Career" Button */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexGrow: 1, // Allows the box to take remaining space
              justifyContent: { xs: "flex-end" }, // Align to end on mobile
              marginTop: { xs: 0, sm: 0 }, // Removes unnecessary margin
              width: { xs: "100%", sm: "auto" }, // Full width on mobile if needed
            }}
          >
            {/* Tournament Selector */}
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                minWidth: 120,
                width: { xs: "100px", sm: "150px" },
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <InputLabel id="tournament-select-label">Tournament</InputLabel>
              <Select
                labelId="tournament-select-label"
                id="tournament-select"
                value={match}
                onChange={(e) => setMatch(e.target.value)}
                label="Tournament"
                sx={{
                  width: "100%",
                }}
              >
                {tournaments.map((tournament) => (
                  <MenuItem value={tournament.id} key={tournament.id}>
                    {tournament.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* "Go to Career" Button */}
            {!isMobile && (
              <Button
                color="inherit"
                component={RouterLink}
                to="/career"
                startIcon={<AccountCircleIcon />}
                sx={{ textTransform: "none", fontSize: { xs: "0.875rem", sm: "1rem" } }} // Adjust font size
              >
                Go to Career
              </Button>
            )}

            {/* "Go to Career" Icon Button for Mobile */}
            {isMobile && (
              <IconButton
                color="inherit"
                component={RouterLink}
                to="/career"
                sx={{ textTransform: "none" }}
              >
                <AccountCircleIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {/* Spacer Toolbar to prevent content being hidden behind AppBar */}
      {/* Remove the Toolbar spacer and handle spacing via padding-top */}
      {/* <Toolbar /> */}
      {/* End of AppBar Section */}

      {/* Drawer Section */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation folders"
      >
        {/* Mobile Drawer */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* Desktop Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* End of Drawer Section */}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1, sm: 3 },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          pt: { xs: "56px", sm: "64px" }, // Responsive padding-top to offset AppBar
        }}
      >
        {/* Header with Tournament Name and Last Updated */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center", sm: "flex-start" },
            justifyContent: "space-between",
            textAlign: { xs: "center", sm: "left" },
            gap: { xs: 1, sm: 0 },
            mb: 3,
          }}
        >
          <Typography variant="h5" component="h1">
            Data for {selectedTournament ? selectedTournament.name : "Unknown Tournament"}
          </Typography>
          <Typography variant="body1">
            Data last updated on: {process.env.REACT_APP_LAST_UPDATED || "N/A"}
          </Typography>
        </Box>

        {/* Conditional Rendering Based on Selected List Button */}
        {storeContext.selectedListButton[0] ? (
          <div className="stats">
            {/* Search Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", sm: "flex-start" },
                mb: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* Search Input */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "2px 8px",
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  {/* Replace MenuIcon with SearchIcon if needed */}
                  <SearchIcon sx={{ mr: 1 }} /> {/* Optional: Replace with a Search Icon */}
                  <input
                    type="text"
                    placeholder="Player search"
                    value={storeContext.searchInput}
                    onChange={handleSearchChange}
                    style={{
                      border: "none",
                      outline: "none",
                      width: "150px",
                      backgroundColor: "transparent",
                    }}
                  />
                </Box>
                {/* Search Button */}
                <Button
                  variant="contained"
                  onClick={() => storeContext.handleSearchInput(match)}
                >
                  Search
                </Button>
              </Box>
            </Box>

            {/* Batters DataTable */}
            <Typography variant="h6" gutterBottom>
              Batters
            </Typography>
            <DataTable bat={true} isSearch={false} match={match} />

            {/* Bowlers DataTable */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Bowlers
            </Typography>
            <DataTable bat={false} isSearch={false} match={match} />

            {/* Previous Matches for Searched Player */}
            {storeContext.searchInput.length > 0 && (
              <>
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                  Previous matches for {storeContext.searchInput}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Batting
                </Typography>
                <DataTable bat={true} isSearch={true} />
                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                  Bowling
                </Typography>
                <DataTable bat={false} isSearch={true} />
              </>
            )}

            {/* Win Data Pie Chart */}
            {storeContext.winData && (
              <>
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                  Batting first vs Batting second wins
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                  <PieChart
                    style={{ height: "300px" }}
                    data={storeContext.winData}
                    segmentsShift={(index) => (index === 0 ? 0.5 : 0.5)}
                    label={({ dataEntry }) => dataEntry.value}
                    labelStyle={{
                      ...defaultLabelStyle,
                    }}
                    radius={40}
                  />
                </Box>
              </>
            )}
          </div>
        ) : storeContext.selectedListButton[1] ? (
          <PitchwiseStatistics match={match} />
        ) : storeContext.selectedListButton[2] ? (
          <>
            <Typography variant="h6" gutterBottom>
              Points Table
            </Typography>
            <PointsTable match={match} />
          </>
        ) : null}
      </Box>
      {/* End of Main Content */}
    </Box>
  );
}

// Define PropTypes if needed
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
